@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import "_custom";

$slate-grey: #4D4E5E;
$twilight-blue: #093875;
$azure: #00aaff;
$nw-dark: #04162f;

$dark-blue: $twilight-blue;
$darkBlue: $twilight-blue;
$navy: $twilight-blue;
$warn-yellow: #fbc900;

$white: #fff;
$white-two: #f5f5f5;
$white-three: #ededed;
$white-four: #dfdfdf;

$warm-grey: #9d9d9d;

$red-error-msg: #f41b0b;
$placeholder-color: #ced4da;

$nav-background-color: #4d4e5e;
$nav-text-color: #fff;
$nav-a-color: #e5e5e5;

$forms-optional-color: $warm-grey;
$forms-default-color: $nav-background-color;
$forms-invalid-color: #d0021b;
$forms-inactive-color: #d0d0d0;
$forms-success-color: #2b9700;
$forms-info-color: $warm-grey;
$forms-password-hint-green: #7AC208;
$forms-password-hint-red: #E35235;

$grey1: #EDEDED;
$grey2: #D0D0D0;
$grey3: #9D9D9D;
$grey4: #636363;
$grey5: #f0f0f0;
$grey6: #d8d8d8;
$grey7: #777777;
$grey8: #888888;
$grey9: #999999;

$bright-blue: $azure;

// TODO: refactor apply-boxes to use common colors variables
$bluegrey: #92aeb8;
$cloudy-blue: #a6c3ce;

// fonts
$Balto: Balto, sans-serif;
$BaltoBook: Balto-book, $Balto;
$CircularStd: CircularStd, sans-serif;
$CircularStdBook: CircularStd-Book, sans-serif;
