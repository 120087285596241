@import "variables";
@import "material-theme";

@font-face {
  font-family: Balto;
  src: url('/assets/fonts/balto-book.eot');
  src: url('/assets/fonts/balto-book.eot?#iefix') format('embedded-opentype'),  url('/assets/fonts/balto-book.woff') format('woff'), url('/assets/fonts/balto-book.otf') format('opentype');
}

@font-face {
  font-family: CircularStd;
  src: url('/assets/fonts/CircularStd-Medium.eot');
  src: url('/assets/fonts/CircularStd-Medium.eot?#iefix') format('eot'), url('/assets/fonts/CircularStd-Medium.woff') format('woff'), url('/assets/fonts/CircularStd-medium.otf') format('opentype');
}

@font-face {
  font-family: CircularStd-Book;
  src: url('/assets/fonts/CircularStd-Book.otf') format('opentype');
  src: url('/assets/fonts/CircularStd-Book.eot?#iefix') format('eot'), url('/assets/fonts/CircularStd-Book.woff') format('woff'), url('/assets/fonts/CircularStd-Book.otf') format('opentype');
}

body {
  font-family: Balto, sans-serif;
  font-size: 16px;
  background: none transparent;
}

.btn {
  border-radius: 0;
  border: none;
  font-family: $CircularStdBook;

  &:hover, &:focus, &:active {
    outline: none;
    box-shadow: none;
  }

  &.btn-azure {
    color: $white;
    background: $azure;

    &:hover, &:focus, &:active {
      background: darken($azure, 10%);
    }
  }

  &.btn-grey {
    color: $slate-grey;
    background: $white-three;
    border: 1px solid darken($white-three, 15%);

    &:hover, &:focus, &:active {
      background: darken($white-three, 10%);
    }
  }

  &.btn-slate {
    color: $white;
    background: $slate-grey;

    &:hover, &:focus, &:active {
      background: darken($slate-grey, 10%);
    }
  }

  &.btn-slate {
    color: $white;
    background: $twilight-blue;

    &:hover, &:focus, &:active {
      background: darken($twilight-blue, 10%);
    }
  }

  &.btn-transparent {
    color: $slate-grey;
    background: transparent;
    border: 1px solid #dfdfdf;

    &:hover, &:focus, &:active {
      color: darken($slate-grey, 10%);
      border: 1px solid darken(#dfdfdf, 10%);
    }
  }
}

.form-control {
  &::placeholder {
    color: $placeholder-color;
  }

  &::-moz-placeholder {
    color: $placeholder-color;
  }

  &:-ms-input-placeholder {
    color: $placeholder-color;
  }

  &::-webkit-input-placeholder {
    color: $placeholder-color;
  }
}

.pointer {
  cursor: pointer;
}
